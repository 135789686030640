<template>
  <!-- 项目页面 -->
  <v-container fluid>
    <v-card align-center>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="2">
            <v-btn v-if="$root.authority=='ROLE_USER'" color="primary" @click="showDialog=true;showTemplate=true;createProject()">{{$t('lang.project.newProjectBtn')}}</v-btn>
          </v-col>
          <v-col cols="12" sm="4" offset-sm="6">
            <v-text-field v-model="searchByProject" :label="$t('lang.station.searchLabel')" append-icon="mdi-magnify"></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- 项目表格 -->
      <v-card-text>
        <v-data-table :search="searchByProject" :headers="projectHeaders" :items="projectList">
          <!-- <template v-slot:item.token="props">
            <v-edit-dialog
              :return-value.sync="props.item.token"
            >
              <v-icon>mdi-folder-key-outline</v-icon>
              <template v-slot:input>
                <v-text-field v-model="props.item.token" readonly></v-text-field>
              </template>
            </v-edit-dialog>
          </template> -->
          <template v-slot:item.actions="{item}">
            <!-- 编辑 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary lighten-1" small class="mr-2" @click="showDialog=true;showTemplate=false;editProject(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$t('lang.station.editBtn')}}</span>
            </v-tooltip>
            <!-- 删除 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="error lighten-1" small @click="delProject(item.id)">
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
             </template>
              <span>{{$t('lang.station.deleteBtn')}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.baseStations="{ item }">
            <div v-for="(station,i) in item.baseStations" :key="i">{{station.name}}</div>
          </template>
           <template v-slot:item.description="{ item }">
             <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                 <span v-bind="attrs" v-on="on">{{item.description.length > 10 ? item.description.substring(0,10)+'...' : item.description}}</span>
               </template>
               <span>{{item.description}}</span>
             </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- 项目编辑对话框 -->
    <v-dialog v-model="showDialog" max-width="700">
      <v-card>
        <v-card-title>{{editIndex == -1 ? $t('lang.project.newProjectTitle') : $t('lang.project.projectEditorTitle')}}</v-card-title>
        <!-- 显示模板 -->
        <div v-if="showTemplate">
          <v-card-text>
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                <v-icon color="orange">mdi-bullhorn-variant-outline</v-icon>
                {{$t('lang.project.templateHints')}}
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4.5"
                v-for="(template,i) in templateList"
                :key="i"
                class="d-flex justify-center"
              >
                <v-sheet
                  class="text-center"
                  :elevation="template.selected ? 10 : 1"
                  max-height="200"
                  max-width="230"
                  @click="template.selected = !template.selected;judgeTemplateSelected(template,i)"
                >
                  <span class="subtitle-2">{{template.title}}</span>
                  <v-img :src="template.src"></v-img>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn text color="primary" @click="showTemplate = false">{{$t('lang.project.skipTemplate')}}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="showDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
            <v-btn color="primary" @click="showTemplate = false">{{$t('lang.project.nextStep')}}</v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text>
            <!-- 项目信息表单 -->
            <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-text-field :label="$t('lang.project.projectName')" v-model="form.projectName" :rules="projectNameRules" :counter="20"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select :label="refStationLabelTips" :items="referenceStations" return-object item-text="name" item-value="id" v-model="form.selectedAReferenceStation" v-if="templateList[0].selected" :rules="aReferenceStationRules"></v-select>
                <v-select :label="refStationLabelTips" :items="referenceStations" return-object item-text="name" item-value="id" multiple v-model="form.selectedReferenceStations" v-else :rules="referenceStationsRules"></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea :label="$t('lang.project.projectDescription')" v-model="form.description" :rules="descriptionRules" :counter="300"></v-textarea>
              </v-col>
            </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" @click="showDialog = false">{{$t('lang.station.cancelBtn')}}</v-btn>
            <v-btn color="info" @click="showTemplate = true" v-if="showPreviousStep">{{$t('lang.project.previousStep')}}</v-btn>
            <v-btn color="primary" @click="saveProject()">{{$t('lang.station.saveBtn')}}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="tips.timeout"
      v-model="tips.showSnackbar"
      :color="tips.snackbarColor"
      :top="true"
    >{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import qs from "qs";
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl;
let vm;
export default {
  data() {
    return {
      searchByProject: "",
      projectHeaders: [
        { value: "id", text: this.$t('lang.project.projectId') },
        { value: "name", text: this.$t('lang.project.projectName') },
        { value: 'userId', text: this.$t('lang.register.usernameLabel') },
        { value: "baseStations", text: this.$t('lang.station.referenceStation') },
        { value: "description", text: this.$t('lang.project.projectDescription') },
        //{ value: "token", text: this.$t('lang.project.accessKey'), sortable: false },
        //{ value: "createdTime", text: this.$t('lang.station.creationTime') },
        { value: "actions", text: this.$t('lang.station.operation'), sortable: false },
      ],
      projectList: [
      ],
      showDialog: false,
      templateList: [
        {
          title: this.$t('lang.project.diffPositioning'),
          src: require("../../public/Diff-P.png"),
          selected: false,
          refStationLabel: `${this.$t('lang.station.referenceStation')}`,//(${this.$t('lang.project.radio')})
        },
        {
          title:  this.$t('lang.project.corsNet'),
          src: require("../../public/Cors-P.jpg"),
          selected: false,
          refStationLabel: `${this.$t('lang.station.referenceStation')}`,//(${this.$t('lang.project.checkbox')})
        },
      ],
      showTemplate: true,
      referenceStations: [],//用户自己的参考站或其他设为public的参考站
      form: {
        projectName: '',
        selectedAReferenceStation: '',//只选一个参考站时的选中值
        selectedReferenceStations: [],//可选多个参考站时的选中值
        description: '',
        id: -1
      },
      resetForm: {
        projectName: '',
        selectedAReferenceStation: '', 
        selectedReferenceStations: [],
        description: '',
        id: -1
      },
      projectNameRules: [
        v => !!v || this.$t('lang.project.projectNameRules.required'),
        v => /^[\u4e00-\u9fa5_$a-zA-Z0-9]+$/.test(v) || this.$t('lang.project.projectNameRules.specialCharacterRestriction'),
        v => v.length <= 20 || this.$t('lang.project.projectNameRules.maximumCharacterLimit'),
      ],
      aReferenceStationRules: [
        v => !!v || this.$t('lang.project.referenceStationRules.required'),
      ],
      referenceStationsRules: [
        v => v.length > 0 || this.$t('lang.project.referenceStationRules.required'),
        v => v.length >= 2 || this.$t('lang.project.referenceStationRules.minCounts'),
      ],
      descriptionRules: [
        v => !!v || this.$t('lang.project.descriptionRules.required'),
        v => /^[\u4e00-\u9fa5_$，。；：？“”,.;:? ""a-zA-Z0-9]+$/.test(v) || this.$t('lang.project.descriptionRules.specialCharacterRestriction'),
        v => v.length <= 300 || this.$t('lang.project.descriptionRules.maximumCharacterLimit'),
      ],
      editIndex: -1,
      showPreviousStep: true,
      stationList: [],
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1,
      },
    };
  },
  computed: {
    refStationLabelTips: {
      get() {
        let selectedTemplate = this.templateList.filter(
          (item) => item.selected == true
        );
        if (selectedTemplate.length <= 0) return this.$t('lang.station.referenceStation');
        return selectedTemplate[0].refStationLabel;
      },
      set() {},
    },
  },
  created() {
    vm = this
    this.getProjectList()
  },
  methods: {
    //通知提示工具
    showTips(text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if (timeout) {
        this.tips.timeout = timeout;
      } else {
        this.tips.timeout = 3000;
      }
    },
    //选中单前的模板时，判断另一个模板的选中情况
    judgeTemplateSelected(template, i){
      //没有选中当前模板，跳过
      if(!template.selected) return 
      //判断另一个模板的下标
      if(i == 0) {
        i += 1
      }else {
        i -= 1
      }
      //另一个模板是选中的，取消选中状态
      if(this.templateList[i].selected) this.templateList[i].selected = false
    },
    save() {
      if(!this.$refs.form.validate()) return;
      this.showDialog = false;
    },
    createProject() {
      this.editIndex = -1
      this.form = JSON.parse(JSON.stringify(this.resetForm))
      this.templateList[0].selected = false
      this.templateList[1].selected = false
      this.showPreviousStep = true
      if(this.$refs.form) this.$refs.form.resetValidation()
    },
    //点击项目编辑
    editProject(item) {
      console.log(item)
      this.editIndex = this.projectList.findIndex(project => project.id == item.id)
      this.form = {
        projectName: item.name,
        description: item.description,
        id: item.id
      }
      //根据项目类型设置参考站和模板默认值
      if(item.type == "GENERAL") {
        this.form.selectedAReferenceStation = item.baseStations[0];
        this.templateList[0].selected = true
        if(this.templateList[1].selected) this.templateList[1].selected = false
      }else {
        this.form.selectedReferenceStations = item.baseStations
        this.templateList[1].selected = true
        if(this.templateList[0].selected) this.templateList[0].selected = false
      }
      this.showPreviousStep = false

      //管理员参考站列表包括用户自己的参考站和其他设为public的参考站
      if(this.$root.authority == 'ROLE_ADMIN' || this.$root.authority == 'ROLE_USER_ADMIN'){
        let fixStationsByUser = this.stationList.filter(data => item.userId == data.userId && data.type== 'BASE')
        let otherPublicFixStations = this.stationList.filter(data => item.userId != data.userId && data.type== 'BASE' && data.isPublic)
        this.referenceStations = fixStationsByUser.concat(otherPublicFixStations)
      }
    },
    //获得站列表
    async getStationList() {
      let res = await axios.get(`${apiUrl}station/list`)
      //console.log(res)
      if(res && res.status == 200){
        this.stationList = res.data
        if(this.$root.authority == 'ROLE_USER') {//普通用户
          this.referenceStations = this.stationList.filter(data => data.type == 'BASE')//参考站列表
        }
      }
    },
    //获得项目列表
    getProjectList() {
      axios.get(`${apiUrl}project/list`).then(async(res) => {
        console.log(res.data)
        if(res.status == 200) {
          //站的列表里找到对应参考站，把完整站信息放入项目对象里
          await vm.getStationList()
          for(let project of res.data) {
            project['baseStations'] = []
            if(!project.baseStationIds) continue
            for(let stationId of project.baseStationIds){ 
              let stationObj = vm.stationList.filter(obj => obj.id == stationId)[0]
              project['baseStations'].push(stationObj)
            }
          }
          vm.projectList = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    //添加/编辑项目
    saveProject() {
      if(!this.$refs.form.validate()) return
      this.showDialog = false
      console.log(this.form)
      let param = {
        name: this.form.projectName,
        description: this.form.description,
        baseStationIds: [],
        type: ''
      }
      if(this.templateList[0].selected) {
        param.baseStationIds.push(this.form.selectedAReferenceStation.id)
        param.type = 'GENERAL'
      }else{
        param.baseStationIds =  this.form.selectedReferenceStations.map(station => {return station.id})
        param.type = 'CORS'
      }
      if(this.form.id != -1){
        param['id'] = this.form.id
      }
      console.log(param)
      axios.post(`${apiUrl}project/edit`, param).then(res => {
        if (res.status == 200) {
          this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
          vm.getProjectList();
          return;
        }
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      }).catch(error => {
        console.log(error)
        this.showTips(vm.$t("lang.tips.operationFailed"), "error");
      })
    },
    //删除项目
    delProject(projectId) {
       //删除确认
      this.$dialog
        .confirm({
          text: this.$t("lang.tips.userList.delTips"),
          title: this.$t("lang.tips.userList.delConfirm"),
          cancel: this.$t("lang.station.cancelBtn"),
          actions: [
            {
              text: this.$t("lang.station.cancelBtn"),
              key: "false",
            },
            {
              text: this.$t("lang.package.confirmBtn"),
              color: "blue",
              key: true,
            },
          ],
        })
        .then(async (type) => {
          if (!type) return;
          let param = { id: projectId }
          axios.post(`${apiUrl}project/del`, qs.stringify(param)).then(res => {
            if (res.status == 200) {
              this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
              vm.getProjectList();
              return;
            }
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          }).catch(error => {
            console.log(error)
            this.showTips(vm.$t("lang.tips.operationFailed"), "error");
          })  
        });
    }
  },
};
</script>